import { cancelSignatureRequest, openDropboxSignForClaim, openDropboxSignForSign } from './dropbox_sign'
import { pickFileAndStore } from '../../../utils/filestack'

!function() {

  UC.views.UserDocumentsShow = Backbone.View.extend({

    events: {
      'click #btnRequestAccess': 'requestAccess',
      'click .document-request-signature': 'openChooseSignersModal',
      'click .document-sign': 'openForSign',
      'click .document-delete': 'confirmDestroy',
      'click .document-share': 'openShareModal',
      'click .document-cancel': 'cancelSignatureRequest',
      'click .document-replace': 'createRevision',
      'click .document-rename': 'showRename',
      'click .signer-status-item-resend': 'resendSignatureRequestEmail',
      'blur #txtDocumentRename': 'hideRename',
      'keydown #txtDocumentRename': 'hideRename',
      'change #txtDocumentRename': 'saveRename',
      'click #lnkShowDetails': 'onLnkShowDetails',
      'click #lnkHideDetails': 'onLnkHideDetails'
    },

    elements: {
      'btnRequestAccess': null,
      'txtRequestAccessMessage': null,
      'rowRequestAccess': null,
      'requestAccessStatus': null,
      'boxViewerLoading': null,
      'boxViewerFrame': null,
      'modalConfirmAccount': null,
      'signerStatusMsg': null,
      'signerStatusList': null,
      'lnkShowDetails': null,
      'lnkHideDetails': null,
      'modalAboutUpcounsel': null
    },

    initialize: function() {
      UC.utils.setSelectors(this.elements);
      this.components = {};
      this.elements.lnkShowDetails.hide();
      this.document_id = $('.documents').data('id');
      this.master_id = $('.documents').data('master-id');
      if (UC.user) {
        UC.utils.recordKissEvent('User Document View', {'user_type' : UC.user.type ,'document_id' : this.document_id});
        UC.utils.recordKissClickEvent('.doc-dl-btn','User Document - Download',{'user_type' : UC.user.type ,'document_id' : this.document_id});
        UC.utils.recordKissClickEvent('.document-download','User Document - Download (cog)',{'user_type' : UC.user.type ,'document_id' : this.document_id});
      } else {
        this.modal_confirm_account = new UC.modals.UserDocumentsModalConfirmAccount({
          el: '#modalConfirmAccount'
        }).render();
      };
      // this.modal_visibility = new UC.modals.UserDocumentsModalVisibility();
      this.modal_share = new UC.modals.UserDocumentsModalShare({ parent_view: this });
      this.modal_choose_signers = new UC.modals.UserDocumentsModalChooseSigners({
        parent_view: this,
        submitCallback: this.onPrepareForSigning });
      this.components.btnRequestAccessLoader = this.elements.btnRequestAccess.buttonLoader();

      if (window.location.hash.indexOf('#share') != -1) {
        this.share();
      }

      if (window.location.hash.indexOf('#sign') != -1 && UC.constants.needs_signing == true) {
        this.openForSign();
      }

      if (UC.constants.is_viewable_by && UC.constants.document_view_type == 'box_viewer') {
        this.pollForBox();
      }

      if ($('#document-cog li').length === 0) {
        $('#document-cog').css('visibility','hidden');
      };

      if (UC.constants.showAboutUpcounsel) {
        new UC.partials.modalV9({
          modalTitle: 'What is UpCounsel?',
          modalContent:
            "UpCounsel is making incredible legal services available to every business. " +
            "Access the world's largest community of talented lawyers for first class legal " +
            "services without the high costs and hassles of traditional firms." +
            "<a href='/howitworks'>Learn more.</a>",
          modalFooter: {
            yesTextClass: 'btn-lg btn-primary',
            yesText: 'Ok'
          }
        })
      };

      this.initSignerHistory();
    },

    onLnkShowDetails: function(e) {
      this.elements.lnkShowDetails.hide();
      this.elements.lnkHideDetails.show();
      UC.utils.showPanel(this.elements.signerStatusList);
    },

    onLnkHideDetails: function(e) {
      this.elements.lnkShowDetails.show();
      this.elements.lnkHideDetails.hide();
      UC.utils.hidePanel(this.elements.signerStatusList);
    },

    showRename: function(e) {
      e.preventDefault();
      var $filename = this.$el.find('#documentFileName');
      var $text = this.$el.find('#txtDocumentRename');
      var period = $text.val().lastIndexOf('.');
      $filename.hide();
      $text.width($filename.outerWidth()).removeClass('hidden').select();
      if (period !== -1) {
        // highlight filename sans extension
        $text[0].setSelectionRange(0, period);
      }
    },

    hideRename: function(e) {
      var esc   = e.type === 'keydown' && e.keyCode === 27;
      var enter = e.type === 'keydown' && e.keyCode === 13;

      if (esc || enter || e.type !== 'keydown') {
        e.preventDefault();
        var $link = this.$el.find('#documentFileName');
        var $text = this.$el.find('#txtDocumentRename');
        var name = $text.val();

        if (esc) {
          // revert back to original
          $text.val($link.text());
        } else {
          $link.text($text.val());
        }

        $link.show();
        $text.addClass('hidden');

        // janky title update
        var title = document.title.split(' on ');
        title[0] = name;
        document.title = title.join(' on ');
      }
    },

    saveRename: function() {
      // we want to update the current document only, not the master (child)
      var path = '{0}/{1}'.format(UC.constants.user_documents_path, this.document_id);

      UC.net.put(path, {
        FileName: this.$el.find('#txtDocumentRename').val()
      }, function(err, message) {
        if (err) {
          UC.utils.showNotification(err, UC.constants.results.failure);
        }
      });
    },

    confirmDestroy: function(e) {
      e.preventDefault();

      var that = this;

      function onConfirm(response) {
        that.destroy(that.document_id, function(err, message) {
          if (err) {
            UC.utils.showNotification(err, UC.constants.results.failure);
          } else {
            if (UC.job) {
              window.location = UC.constants.job_documents_path;
            } else {
              window.location = UC.constants.user_documents_path;
            }
          }
        });
      }
  
      new UC.partials.modalV9({
        modalTitle: 'Delete Document',
        modalContent: 'Are you sure you want to delete this document?',
        modalFooter: {
          yesTextClass: 'btn-lg btn-primary js-confirm-delete',
          yesText: 'Confirm',
          noTextClass: 'btn-lg btn-grey-BG',
          noText: 'Cancel'
        },
        renderCallback: function() {
          $('.js-confirm-delete').on('click', function() { onConfirm(); });
        }
      });
    },

    destroy: function(document_id, callback) {
      UC.utils.recordKissEvent('User Document Cog', {'action':'delete','document_id' : document_id});
      UC.net.destroy('{0}/{1}'.format(UC.constants.user_documents_path, document_id), {
        job_id: UC.constants.jobId
      }, $.proxy(function(err, message) {
        if (typeof callback === 'function') {
          callback.call(this, err, message);
        }
      }, this));
    },

    processRevisions: function(files, document_id) {

      UC.net.post("{0}/{1}/revision".format(UC.constants.user_documents_path, document_id), {
        files: files,
        job_id: UC.constants.jobId
      }, function(err, response) {
        location.reload();
      })
      .error($.proxy(function() {
      }, this));
    },

    createRevision: function(e) {
      e.preventDefault();

      UC.utils.recordKissEvent('User Document Cog', {'action':'create_revision','document_id' : this.master_id});

      this.openFilePicker($.proxy(function(InkBlobs) {
        this.processRevisions(InkBlobs, this.master_id);
      }, this))
    },

    openFilePicker: function(callback) {
      UC.utils.recordKissEvent('Open File Picker - User Document', {'user_type':UC.user.type});
      var path = 'user_{0}/'.format(UC.user.id);

      if (UC.constants.jobId) {
        path += 'job_{0}/'.format(UC.constants.jobId);
      }

      pickFileAndStore({
        pickerOptions: {
          fromSources: ['local_file_system', 'box', 'dropbox', 'googledrive', 'onedrive'],
          uploadInBackground: false,
          onUploadDone: function (res) {
            if (res.filesUploaded && res.filesUploaded.length > 0) {
              callback.call(this, res.filesUploaded);
            }
          }.bind(this)
        },
        storeOptions: {
          location: 's3',
          path: path
        }
      })
    },

    openShareModal: function(e) {
      e.preventDefault();
      UC.utils.recordKissEvent('User Document Cog', {'action':'share','document_id' : this.document_id});
      this.modal_share.render({
        document_ids: [this.document_id]
      });
    },

    openChooseSignersModal: function(e) {
      e.preventDefault();
      UC.utils.recordKissEvent('User Document Cog', {'action':'request_signature', 'document_id' : this.document_id});
      this.modal_choose_signers.render({
        document_id: this.document_id
      });
    },

    onPrepareForSigning: function(data){
      openDropboxSignForClaim(data, this);
    },

    openForSign: function(e){
      if (e) e.preventDefault();

      openDropboxSignForSign(this.document_id);
    },

    cancelSignatureRequest: function(e) {
      e.preventDefault();

      cancelSignatureRequest(this.document_id);
    },

    showSuccessBanner: function(){
      this.elements.signerStatusMsg.removeClass('yellow');
      this.elements.signerStatusMsg.addClass('green');
      this.elements.signerStatusMsg.find('p').text('You have successfully signed this document.');
    },

    pollForBox: function() {

      var $window = $(window);

      $window.resize(function() {
        // no less than 600px
        var height = _.max([$window.height() * .8, 600]);
        // no more than 1400px
        height = _.min([height, 1400]);
        this.elements.boxViewerFrame.height(height);
      }.bind(this)).resize();

      this.loadBoxViewer();
      this.interval = setInterval($.proxy(function() {
        this.loadBoxViewer();
      }, this), 500);
    },

    loadBoxViewer: function() {
      if (this.requesting) return false;

      this.requesting = true;

      UC.net.get(UC.constants.box_url_path, function(result) {
        this.requesting = false;

        if (result.viewer_url && result.viewer_url != '') {
          clearInterval(this.interval);
          this.elements.boxViewerLoading.remove();
          this.elements.boxViewerFrame.attr('src', result.viewer_url);
        }
      }.bind(this)).fail(function(xhr) {
        clearInterval(this.interval);
        xhr.handled = true;
        var data = xhr.responseJSON || {message: 'There was an error loading the document preview.'};
        this.elements.boxViewerLoading.removeClass('hidden').html(data.message);
      }.bind(this));
    },

    share: function(e) {
      if (e) e.preventDefault();
      this.modal_share.render({
        document_ids: [this.document_id]
      });
    },

    requestAccess: function(e) {
      UC.utils.recordKissEvent('User Document Request Access', {'user_type' : UC.user.type ,'document_id' : this.document_id});
      e.preventDefault();

      var that = this;
      var path = "{0}/{1}/request_access".format(UC.constants.user_documents_path, this.document_id);
      var data = { Message: this.elements.txtRequestAccessMessage.val() };

      if (UC.job) {
        data['job_id'] = UC.job.id;
      }

      this.components.btnRequestAccessLoader.showLoader();

      UC.net.post(path, data, function(res) {

        UC.utils.showNotification(res.message, UC.constants.results.success);
        that.components.btnRequestAccessLoader.hideLoader();
        that.elements.rowRequestAccess.remove();
        that.elements.requestAccessStatus.text('Your request to view this document has been sent to the client. You will be notified by email if the client grants your request.');
        that.elements.btnRequestAccess.
        attr('class', 'btn btn-lg border disabled marg-y-20').
        text('Access Pending').
        unbind();

      }).fail(function(res) {

        UC.closeTheLoop.handleFailure(res, false);
        that.components.btnRequestAccessLoader.hideLoader();

      });
    },

    resendSignatureRequestEmail: function(e) {
      function onResendSignatureRequestEmail(err, result) {
        if (err) {
          UC.utils.showNotification(err, UC.constants.results.failure);
        } else {
          var $notification = $('.js-success-notification');
          $notification.find('.flash-message').text(result);
          $notification.removeClass('hidden').addClass('pad-y-20');
          $(e.target).hide();
        }
      }

      e.preventDefault();
      var $row = $(e.target).closest('.signer-status-item'),
          data = {
            userId: $row.data('id'),
            job_id: UC.constants.jobId
          };
      UC.net.post('/signatures/user_document/{0}/resend_request_email'.format(this.document_id), data, onResendSignatureRequestEmail);
    },

    initSignerHistory: function() {

      this.$el.find('.signer-status-item-view-history').popover({
        placement: 'left auto',
        html: true,
        trigger: 'hover',
        content: function() {
          return $(this).closest('.signer-status-item').find('.signer-status-item-history').html();
        }
      }).click(function(e) {
        e.preventDefault();
      });
    }
  });
}();
