!function() {
  UC.views.AttorneyIncomeCalculatorShow = Backbone.View.extend({

    events: {
      'click .fancy-tile-display': 'toggleLegalTileSelection',
      'click .btn-period-switch': 'toggleDisplayedPeriod',
      'click #btnCalculate': 'validateAndUpdateReport',
      'click #btnReset': 'resetReport',
      'click #btnGetReport': 'onGetReportClick',
      'keyup #txtHoursAvailable': 'onAvailableHoursKeyUp',
      'keydown #txtHoursAvailable': 'onNumberKeyDown',
      'keyup #txtHourlyRate': 'onHourlyRateKeyUp',
      'keydown #txtHourlyRate': 'onNumberKeyDown',
      'keyup #txtDesiredIncome': 'onDesiredIncomeKeyUp',
      'keydown #txtDesiredIncome': 'onNumberKeyDown',
    },

    initialize: function() {
      this.hoursAvailablePerWeek = NaN;
      this.hourlyRate = NaN;
      this.desiredIncomePerWeek = NaN;
      this.reportPeriod = 'annual';

      this.resetReport();

      window.incomeCalculatorRecaptchaCallback = function() {
        $('#btnGetReport').attr('disabled', null);
      }
      window.incomeCalculatorRecaptchaErrorCallback = function() {
        $('#btnGetReport').attr('disabled', 'disabled');
      }
    },

    onNumberKeyDown: function(event) {
      if(isNaN(event.key) && event.key !== 'Backspace') {
        event.preventDefault();
      }
    },

    onAvailableHoursKeyUp: function (e) {
      this.hoursAvailablePerWeek = parseInt($(e.currentTarget).val())

      this.updateDesiredIncomePerWeekInput();
    },

    onHourlyRateKeyUp: function (e) {
      this.hourlyRate = parseInt($(e.currentTarget).val())

      this.updateDesiredIncomePerWeekInput();
    },

    onDesiredIncomeKeyUp: function (e) {
      this.desiredIncomePerWeek = parseInt($(e.currentTarget).val())

      this.updateHoursAvailablePerWeekInput();
    },

    updateDesiredIncomePerWeekInput: function() {
      if (isNaN(this.hoursAvailablePerWeek) || isNaN(this.hourlyRate)) {
        return
      }

      this.desiredIncomePerWeek = this.hoursAvailablePerWeek * this.hourlyRate;
      $('#txtDesiredIncome').val(this.desiredIncomePerWeek);
    },

    updateHoursAvailablePerWeekInput: function() {
      if (isNaN(this.desiredIncomePerWeek) || isNaN(this.hourlyRate)) {
        return
      }

      const calculatedHours = Math.trunc(this.desiredIncomePerWeek / this.hourlyRate);
      if (calculatedHours > 40) {
        this.hoursAvailablePerWeek = 40;
        this.hourlyRate = Math.trunc(this.desiredIncomePerWeek / this.hoursAvailablePerWeek);
        $('#txtHourlyRate').val(this.hourlyRate);
      } else {
        this.hoursAvailablePerWeek = calculatedHours;
      }
      $('#txtHoursAvailable').val(this.hoursAvailablePerWeek);
    },

    toggleLegalTileSelection: function(e) {
      e.preventDefault();

      const $checkbox = $(e.currentTarget).closest('.fancy-tile-container').find('input');
      $checkbox.prop('checked', !$checkbox.prop('checked'));
    },

    toggleDisplayedPeriod: function(e) {
      e.preventDefault();

      const $button = $(e.currentTarget);
      $button.closest('.row').find('button.active').toggleClass('active');
      $button.toggleClass('active');

      this.reportPeriod = $button.data('period');
      this.updateReport();
    },

    validateInputValues: function() {
      UC.utils.clearValidationErrors('form-inputs .form-group');

      let errors = [];

      if (isNaN(this.hoursAvailablePerWeek) || this.hoursAvailablePerWeek === 0) {
        errors.push({
          className: 'form-group',
          element: $('#txtHoursAvailable'),
          text: 'required'
        });
      }

      if (isNaN(this.hourlyRate) || this.hourlyRate === 0) {
        errors.push({
          className: 'form-group',
          element: $('#txtHourlyRate'),
          text: 'required'
        });
      }

      if (isNaN(this.desiredIncomePerWeek) || this.desiredIncomePerWeek === 0) {
        errors.push({
          className: 'form-group',
          element: $('#txtDesiredIncome'),
          text: 'required'
        });
      }

      if (errors.length > 0) {
        UC.utils.showValidationErrors(errors, {className: 'form-inputs .form-group'});
        return false;
      }

      return true;
    },

    validateAndUpdateReport: function (e) {
      if (e) {
        e.preventDefault();
      }

      if (this.validateInputValues()) {
        this.updateReport();
      }
    },

    updateReport: function () {
      if (isNaN(this.hoursAvailablePerWeek) || isNaN(this.hourlyRate) || isNaN(this.desiredIncomePerWeek)) {
        return
      }

      const weeklyWorkDays = 5;
      const dailyHoursWorked = this.hoursAvailablePerWeek / weeklyWorkDays;
      const numberOfProposalsNeeded = Math.round(dailyHoursWorked * 4 * ((this.reportPeriod === 'annual' ? 260 : (this.reportPeriod === 'month' ? 22 : weeklyWorkDays))));
      const minutesNeededToSubmitProposal = 4;
      const totalTimeSpent = Math.round(numberOfProposalsNeeded * minutesNeededToSubmitProposal / 60);
      const dailyIncome = dailyHoursWorked * this.hourlyRate;
      const totalIncome = dailyIncome * (this.reportPeriod === 'annual' ? 260 : (this.reportPeriod === 'month' ? 22 : weeklyWorkDays));

      const USDollar = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });

      $('#spanNumberOfProposalsNeeded').text(numberOfProposalsNeeded);
      $('#spanMinutesToSubmitProposal').text(minutesNeededToSubmitProposal);
      $('#spanTotalTimeSpent').text(`${totalTimeSpent} hrs`);
      $('#spanDailyHoursWorked').text(dailyHoursWorked);
      $('#spanHourlyRate').text(USDollar.format(this.hourlyRate));
      $('#spanDailyIncome').text(USDollar.format(dailyIncome));
      $('#spanTotalIncome').text(USDollar.format(totalIncome));
      $('#spanTotalIncomePeriod').text(this.reportPeriod);

      this.updateChart(totalIncome);
    },

    updateChart: function(totalIncome) {
      let chartData = [];
      let yAxisTickPositions = null;
      let xAxisCategories = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      if (isNaN(totalIncome)) {
        // build 'empty state'
        chartData = _.map(xAxisCategories, function(month) {
          return [month, NaN];
        });
        yAxisTickPositions = [100000, 125000, 150000, 175000, 200000, 225000, 250000, 275000, 300000];
      } else {
        // build chart series
        if (this.reportPeriod === 'annual') {
          const monthIncome = totalIncome / 12;
          chartData = _.map(xAxisCategories, function(month, index) {
            return [month, monthIncome * (index + 1)];
          });
        } else if (this.reportPeriod === 'month') {
          xAxisCategories = ['Week 1', 'Week 2', 'Week 3', 'Week 4'];
          const weekIncome = totalIncome / 4;
          chartData = _.map(xAxisCategories, function(week, index) {
            return [week, weekIncome * (index + 1)];
          });
        } else {
          xAxisCategories = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'];
          const dayIncome = totalIncome / 5;
          chartData = _.map(xAxisCategories, function(day, index) {
            return [day, dayIncome * (index + 1)];
          });
        }
      }

      $('#incomeGraph').highcharts({
        chart: {
          type: 'area'
        },
        exporting: { enabled: false },
        title: {
          text: ''
        },
        yAxis: {
          tickPositions: yAxisTickPositions,
          showEmpty: true,
          title : {
            text : ' '
          },
          labels: {
            style: {
              color: '#545E6B',
              fontSize: '13px',
            }
          }
        },
        xAxis: {
          categories: xAxisCategories,
          gridLineWidth: 1,
          labels: {
            style: {
              color: '#545E6B',
              fontSize: '13px',
            }
          },
        },
        credits: {
          enabled: false
        },
        series: [{
          showInLegend: false,
          name: '',
          data: chartData,
          marker: {
            enabled:false
          },
          color: '#009DDC',
          lineColor: '#009DDC',
          fillOpacity: 0.2,
          enableMouseTracking: false
        }],
        accessibility: {
          enabled: false
        }
      });
    },

    resetReport: function(e) {
      if (e) {
        e.preventDefault();
      }

      UC.utils.clearValidationErrors('form-inputs .form-group');

      $('#spanNumberOfProposalsNeeded').text('');
      $('#spanMinutesToSubmitProposal').text('');
      $('#spanTotalTimeSpent').text('');
      $('#spanDailyHoursWorked').text('');
      $('#spanHourlyRate').text('');
      $('#spanDailyIncome').text('');
      $('#spanTotalIncome').text('');
      $('#spanTotalIncomePeriod').text('');

      $('#txtHoursAvailable').val('');
      $('#txtHourlyRate').val('');
      $('#txtDesiredIncome').val('');

      this.hoursAvailablePerWeek = NaN;
      this.hourlyRate = NaN;
      this.desiredIncomePerWeek = NaN;

      $('#btnDefaultPeriod').click();

      this.updateChart();

      UC.utils.clearValidationErrors('form-report .form-group');
      $('#txtEmail').val('');
      $('.form-report .fancy-tile-container input').prop('checked', false);
    },

    onGetReportClick: function(e) {
      e.preventDefault();

      UC.utils.clearValidationErrors('form-report .form-group');

      if (!this.validateInputValues()) {
        UC.utils.scrollToElement($('#calculator-inputs'));
        return
      }

      let errors = [];
      const $emailInput = $('#txtEmail');
      const email = $emailInput.val();
      const checkedLegalTiles = $('.form-report .fancy-tile-container input:checked');

      if (!email || email.length === 0) {
        errors.push({
          className: 'form-group',
          element: $emailInput,
          text: 'required'
        });
      } else if (!UC.utils.validateEmail(email)) {
        errors.push({
          className: 'form-group',
          element: $emailInput,
          text: 'Please provide a valid email address'
        });
      }

      if (checkedLegalTiles.length === 0) {
        errors.push({
          className: 'form-group',
          element: $('.tiles-row'),
          text: 'At least one practice area must be selected'
        });
      }

      if (errors.length > 0) {
        UC.utils.showValidationErrors(errors, {className: 'form-inputs .form-group'});
      } else {
        const buttonLoader = $('#btnGetReport').buttonLoader();
        let data = {
          email: email,
          hourly_rate: this.hourlyRate,
          hours_per_week: this.hoursAvailablePerWeek,
          service_type: _.map(checkedLegalTiles, function(one) {
            return $(one).val();
          }).join(',')
        }
        const recaptchaData = this.$('textarea.g-recaptcha-response').val();
        if (recaptchaData) {
          data['g-recaptcha-response'] = recaptchaData
        }
        buttonLoader.showLoader();
        UC.net.post(
            '/income_calculator/get-report',
            data,
            function (response) {
              buttonLoader.hideLoader();
              UC.utils.showNotification(response.message, UC.constants.results.success);
              this.resetReport();
              this.updateRecaptchaFromApiResponse(response);
            }.bind(this)
        ).fail(function(response) {
          buttonLoader.hideLoader();
          this.updateRecaptchaFromApiResponse(response);
        }.bind(this));
      }
    },

    updateRecaptchaFromApiResponse: function(response) {
      if (!response) {
        return
      }
      if (response.responseJSON) {
        response = response.responseJSON;
      }
      if (response.recaptcha) {
        $('#recaptchaTags').html(response.recaptcha);
      }
    },
  })
}();
